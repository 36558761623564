// useContentfulIconImage.js
import { graphql, useStaticQuery } from "gatsby";

export default (assetUrl) => {
  const { allContentfulAsset } = useStaticQuery(
    graphql`
      query CONTENTFUL_ICON_IMAGE_QUERY {
        allContentfulAsset(
          filter: { file: { contentType: { ne: "application/pdf" } } }
        ) {
          nodes {
            id
            contentful_id
            __typename
            file {
              url
            }
            gatsbyImageData(aspectRatio: 2.22, width: 1000)
          }
        }
      }
    `
  );

  const fluidAsset = allContentfulAsset.nodes.find(
    (n) => n?.file?.url === assetUrl
  );

  return fluidAsset;
};
