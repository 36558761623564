/** @jsx jsx */
import { jsx, Container, Themed } from "theme-ui";
import { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import BreadCrumbs from "../components/BreadCrumbs";
import ContentHubPager from "../components/ContentHubPager";
import Helmet from "react-helmet";
import { getUrlFromSlug } from "../utils/utils";
import { useSitePages } from "../hooks/useSitePages";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { getLocalizedUrlAndTitleFromSlug } from "../utils/utils";
import { LocalizationContext } from "../context/LocalizationContext";
import { useContentHubQueryParamFiltering } from "../hooks/useContentHubQueryParamFiltering";

const ContentHubTagPageTemplate = ({ data, pageContext }) => {
  const nodes = useSitePages();
  const { translate } = useContext(LocalizationContext);

  const { defaultDescription, siteUrl } = useSiteMetadata();

  const { language, slug, parentPage, hidden, contentType, topic } =
    pageContext;

  const { title: localizedNewsTitle, slug: localizedNewsSlug } =
    getLocalizedUrlAndTitleFromSlug(`/${parentPage.slug}`, language, nodes);

  const currentPageSlug = `/${parentPage.slug}/${slug}`;

  const allPages = data.allContentfulContentHubPage.edges;
  const title = pageContext.contentType
    ? pageContext.contentType
    : pageContext.topic;
  const metadataTitle = title;

  const { resultCount, resultPages } = useContentHubQueryParamFiltering({
    allPages,
    contentType,
    topic,
  });

  return (
    <Layout pageContext={pageContext} pageType={"Private Customer"}>
      <SEO
        lang={pageContext.language}
        title={metadataTitle}
        description={defaultDescription}
        keywords={""}
        paths={pageContext.paths}
        hidden={hidden}
      />
      <Container>
        <BreadCrumbs
          slug={currentPageSlug}
          navigationTitle={title}
          parentPage={{
            slug: localizedNewsSlug,
            navigationTitle: localizedNewsTitle,
          }}
          language={language}
        />
      </Container>
      <Container id="content" sx={{ py: [3, null, 5] }}>
        <Themed.h1
          sx={{
            my: 0,
          }}
        >
          {title}
        </Themed.h1>
        <Themed.p
          sx={{
            my: 0,
          }}
        >
          {translate(
            resultCount > 1
              ? "contentHub.multipleResultsFound"
              : "contentHub.singleResultFound",
            { resultCount },
            language
          )}
        </Themed.p>
        <ContentHubPager
          contentHubPages={resultPages}
          sx={{ my: "2em" }}
          language={language}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($node_locale: String!) {
    allContentfulContentHubPage(
      filter: { node_locale: { eq: $node_locale }, hidden: { ne: true } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          authors {
            authorHash
            name
          }
          id
          contentful_id
          __typename
          theme {
            theme
            parentPage {
              slug
            }
          }
          lead
          slug
          title
          contentType {
            name
            node_locale
            slug
            icon {
              fluid {
                ...GatsbyContentfulFluid
              }
              file {
                url
              }
            }
            parentPage {
              slug
            }
          }
          node_locale
          headerImage {
            gatsbyImageData
            description
          }
          parentPage {
            slug
          }
          publishDate(formatString: "X")
          topic {
            name
          }
        }
      }
    }
  }
`;

export default ContentHubTagPageTemplate;
