import { useState, useEffect } from "react";

const includesTags = (tagsToMatch, ...pageTags) => {
  return tagsToMatch.some((tagToMatch) => {
    return pageTags.some(
      (tag) =>
        tagToMatch.localeCompare(tag, undefined, {
          sensitivity: "accent",
        }) === 0
    );
  });
};

export const filterContentHubPages = (
  pages,
  themes,
  contentTypes,
  topics,
  authors
) => {
  return pages.filter((page) => {
    let themeMatched;
    let contentTypeMatched;
    let topicsMatched;
    let authorMatched;

    const { contentType, theme, topic } = page.node;
    const author = page.node.authors;

    if (!themes || themes.length === 0) {
      themeMatched = true;
    } else {
      themeMatched =
        theme && themes && includesTags(themes, theme.name, theme.theme);
    }

    if (!contentTypes || contentTypes.length === 0) {
      contentTypeMatched = true;
    } else {
      contentTypeMatched =
        contentType &&
        contentTypes &&
        includesTags(contentTypes, contentType.name, contentType);
    }

    if (!topics || topics.length === 0) {
      topicsMatched = true;
    } else {
      topicsMatched =
        topic &&
        topic.filter((item) => includesTags(topics, item.name)).length > 0;
    }

    if (!authors || authors.length === 0) {
      authorMatched = true;
    } else {
      authorMatched =
        author &&
        author.filter((item) => includesTags(authors, item.name)).length > 0;
    }
    const articleWhereTypeThemeTopicAuthorMatched =
      themeMatched && contentTypeMatched && topicsMatched && authorMatched;

    return articleWhereTypeThemeTopicAuthorMatched;
  });
};

export const useContentHubQueryParamFiltering = ({
  allPages = [],
  theme = "",
  contentType = "",
  topic = "",
  author = "",
}) => {
  const [resultCount, setResultCount] = useState(allPages.length);
  const [resultPages, setResultPages] = useState(allPages);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryContentTypes = queryParams.getAll("type");
    const queryTopics = queryParams.getAll("topic");
    const queryThemes = queryParams.getAll("theme");
    const queryAuthor = queryParams.getAll("author");

    const newPages = filterContentHubPages(
      allPages,
      [theme, ...queryThemes].filter((item) => item),
      [contentType, ...queryContentTypes].filter((item) => item),
      [topic, ...queryTopics].filter((item) => item),
      [author, ...queryAuthor].filter((item) => item)
    );

    setResultCount(newPages.length);
    setResultPages(newPages);
  }, []);

  return { resultCount, resultPages };
};
