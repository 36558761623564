import { useStaticQuery, graphql } from "gatsby";

export const useAllContentHubThemePages = () => {
  const { allContentfulContentHubThemePage } = useStaticQuery(
    graphql`
      query AllContentHubThemePages {
        allContentfulContentHubThemePage {
          nodes {
            id
            contentful_id
            __typename
            slug
            theme
            node_locale
          }
        }
      }
    `
  );

  return allContentfulContentHubThemePage.nodes || [];
};
