/** @jsx jsx */
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import { useContext } from "react";
import { Box, Flex, jsx, Themed } from "theme-ui";
import defaultImage from "../../static/fennia-some-default.png";
import { LocalizationContext } from "../context/LocalizationContext";
import { useAllContentHubThemePages } from "../hooks/useAllContentHubThemePages";
import Link from "./Link";
import useContentfulIconImage from "../hooks/useContentfulIconImage";

export const ContentCardTypes = {
  FRONTPAGE: "FRONTPAGE",
  THEMEPAGE: "THEMEPAGE",
  GENERIC: "GENERIC",
};

const createImageWithCorrectAspectRatio = (headerImage) => {
  let imageSrc = getSrc(headerImage);
  const image = useContentfulIconImage(imageSrc?.split("?")[0]);
  return image;
};

const ContentCardList = ({
  title,
  contentHubPages,
  language,
  index = "none",
  type = ContentCardTypes.THEMEPAGE,
  id,
  targetSlug,
  noHeroOnPage,
}) => {
  const { translate } = useContext(LocalizationContext);
  const contentHubThemePages = useAllContentHubThemePages();
  const { slug: currentThemePageSlug } =
    contentHubThemePages.find((themePage) => themePage.theme === title) || {};

  const slug =
    type === ContentCardTypes.GENERIC ? targetSlug : currentThemePageSlug;

  if (!Array.isArray(contentHubPages)) return null;

  const renderItem = (
    { title, lead, contentType, slug, headerImage, parentPage },
    index
  ) => {
    const { name: contentTypeName, icon } = contentType;
    const pageSlug = `/${parentPage.slug}/${slug}`;

    const image = createImageWithCorrectAspectRatio(headerImage);

    return (
      <Box
        key={`${pageSlug}`}
        sx={{ m: "0.5em", width: ["100%", "66%", "33%"] }}
      >
        {
          <Link
            target={pageSlug}
            aria-labelledby={`content-card-title-${slug}-${index}`}
            sx={{
              position: "relative",
              display: "block",
              transition: "all .15s ease",
              ":hover": {
                transform: "scale(1.05) translateY(-2.5%)",
              },
            }}
          >
            {headerImage && image ? (
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={headerImage.description ? headerImage.description : ""}
                sx={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Box sx={{ position: "relative" }}>
                <div sx={{ width: "100%", paddingBottom: "45%" }} />
                <img
                  src={defaultImage}
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "50% 50%",
                  }}
                  aria-hidden="true"
                  alt=""
                />
              </Box>
            )}
          </Link>
        }
        <Box>
          {renderIcon(icon)}
          <Themed.p
            sx={{
              marginLeft: "0.5em",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {contentTypeName}
          </Themed.p>
        </Box>
        <Themed.h3 sx={{ mt: 0, mb: 0 }}>
          <Link
            target={pageSlug}
            id={`content-card-title-${slug}-${index}`}
            sx={{ color: "black", ":hover": { color: "text", opacity: 0.9 } }}
          >
            {title}
          </Link>
        </Themed.h3>
        <Themed.p>{lead}</Themed.p>
        <Link
          target={pageSlug}
          id={`content-card-read-more-${slug}-${index}`}
          aria-labelledby={`content-card-read-more-${slug}-${index} content-card-title-${slug}-${index}`}
          showArrow
        >
          {translate("contentHub.readMore", null, language)}
        </Link>
      </Box>
    );
  };

  const renderIcon = (icon) => {
    if (!icon) return null;

    if (icon.gatsbyImageData) {
      return (
        <GatsbyImage
          image={icon.gatsbyImageData}
          alt=""
          sx={{
            verticalAlign: "middle",
            width: "32px",
            height: "32px",
            display: "inline-block",
          }}
        />
      );
    }

    return (
      <img
        src={icon.file && icon.file.url}
        alt=""
        sx={{
          verticalAlign: "middle",
          width: "32px",
          height: "32px",
          display: "inline-block",
        }}
      />
    );
  };

  const contentMatrix = contentHubPages.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / 3);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }

    acc[chunkIndex].push(renderItem(item.node || item, index));

    return acc;
  }, []);

  if (contentMatrix.length < 1) {
    return null;
  }

  return (
    <Box id={id} aria-live="polite">
      <Flex sx={{ flexWrap: "wrap", alignItems: "center", marginTop: "1em" }}>
        {title &&
          (type === ContentCardTypes.THEMEPAGE && noHeroOnPage ? (
            <Themed.h1
              sx={{
                display: "inline-block",
                my: 0,
                marginBottom: "0.2em",
                marginRight: "1em",
              }}
              id={`content-card-title-${slug}-${index}`}
            >
              {title}
            </Themed.h1>
          ) : (
            <Themed.h2
              sx={{
                display: "inline-block",
                my: 0,
                marginBottom: "0.2em",
                marginRight: "1em",
              }}
              id={`content-card-title-${slug}-${index}`}
            >
              {title}
            </Themed.h2>
          ))}
        {type === ContentCardTypes.FRONTPAGE && (
          <Link
            target={slug}
            showArrow
            sx={{
              display: "inline-block",
              ml: [null, "auto", "auto", "auto"],
              my: 0,
            }}
            id={`content-card-read-more-${slug}-${index}`}
            aria-labelledby={`content-card-read-more-${slug}-${index} content-card-title-${slug}-${index}`}
          >
            {translate("contentHub.moreDetails", null, language)}
          </Link>
        )}
      </Flex>
      {contentMatrix.map((row, i) => {
        return (
          <Flex
            key={`contentcard-${i}`}
            sx={{
              justifyContent: "start",
              mx: "-0.5em",
              flexWrap: ["wrap", "wrap", "nowrap"],
            }}
          >
            {row}
          </Flex>
        );
      })}
    </Box>
  );
};

export default ContentCardList;
